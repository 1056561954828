import Layout from '@components/ACOLayout/ACOLayout';
import PageNotFound from '@components/PageNotFound/PageNotFound';
import AcoSEO from '@components/SEO/AcoSEO';
import * as Sentry from '@sentry/nextjs';
import { createSSRContinuationContext } from '@server/shared/request-continuation';
import { RedirectError, serverSidePropsBuilder } from '@server/shared/server-side-props';
import { safeLegacyUrlResolver } from '@service/backend/backend.url.resolver';
import { isProduction } from '@shared/environment';
import React from 'react';

const FourOFour = () => {
  return (
    <Layout withoutRightColumn>
      <AcoSEO nextSEO={{ title: 'Error 404' }} />
      <PageNotFound />
    </Layout>
  );
};

export default FourOFour;

export const getServerSideProps = serverSidePropsBuilder<unknown>()
  .use(createSSRContinuationContext())
  .handle(async (ctx) => {
  if (isProduction) {
    await Sentry.captureUnderscoreErrorException(ctx);
  }

  const resolve = await safeLegacyUrlResolver(ctx);

  if (resolve.match && resolve.to) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    throw new RedirectError(`Resolved legacy URL to new path ${ctx.req.url} -> ${resolve.to}`, {
      permanent: true,
      destination: resolve.to!,
    });
  }

  return { props: {} };
});
